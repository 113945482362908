<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p><img class="size-full wp-image-23332 aligncenter" src="@/assets/news/12/1.jpg" alt="图片" width="800"  sizes="(max-width: 1080px) 100vw, 1080px"></p>
        <p><span style="color: #0000ff;"><strong>爱尔兰项目好评不断</strong></span></p>
        <p>我们很多移居爱尔兰的老客户，对爱尔兰的评价都很高，认为爱尔兰堪比瑞士。不管您关心的是教育环境、社会安定程度，还是就业经商或养老，爱尔兰身份都将给您满意的安排。</p>
        <p>投资移民是涉及家庭规划、资产配置的一件大事，甚至可能影响子女的一生。而爱尔兰投资移民系列项目（福利房项目和养老院项目）在政府的支持下更是成为了海外投资者完成身份规划的一条重要渠道。</p>
        <p>在重大决定之前，许多投资人可能会呈现迟疑不决、不断对比、只选不移的状态，往往错过了投资移民门槛较低、政策较宽松的较佳时机。过往受欢迎的英语国家的移民政策往往都历经涨价、变政、缩紧，让投资人们或追加大笔投资金额、或延误了投资移民时间、或花费更多准备材料的精力、或让子女的海外教育受到影响。如果您此刻在考虑为自己和家人做身份规划，建议您抓紧爱尔兰的政策宽松期，早日规划，运筹帷幄。</p>
        <p>我们将会持续保持关注，向大家汇报爱尔兰相关的新动态。</p>
        <p><span style="color: #0000ff;"><strong>爱尔兰投资移民优势</strong></span></p>
        <p><strong>优质教育：</strong></p>
        <p>•爱尔兰教育系统排名世界第七*</p>
        <p>•爱尔兰所有大学都排名世界前列</p>
        <p>•欧盟国家、美国和英国的知名企业青睐爱尔兰毕业生</p>
        <p>•子女免费就读公立小学和中学，大学的学费远低于英美</p>
        <p>•爱尔兰和英国互相承认中学学分</p>
        <p>*资料来源：2019年IMD世界竞争力排名</p>
        <p><strong>就业优势：</strong></p>
        <p>•Google, Facebook, IBM, Accenture, 辉瑞等超过1150家跨国企业将欧洲总部设在爱尔兰</p>
        <p>•大量优质、高薪的就业机会，如，Google在爱尔兰雇员人数超7,000人，Apple超6,000人</p>
        <p>•2021年，爱尔兰全职员工的平均年收入为53817欧元</p>
        <p>•约78%的爱尔兰毕业生离校后九个月内就能找到工作</p>
        <p><strong>投资与经商宝地：</strong></p>
        <p>•2014-2018连续五年经济增长率保持欧盟超高水平</p>
        <p>•2011-2017年度IBM全球区域趋势报告称爱尔兰为外资直接投资量超大国</p>
        <p>•2017年度IMD世界竞争力年鉴评价爱尔兰为优秀投资回报国</p>
        <p>•2013年福布斯评价爱尔兰为世界优质经商国</p>
        <p>•国家综合知识、国家繁荣度和平等水平排名世界前列</p>
        <p>•企业所得税税率低至12.5%</p>
        <p><strong>移民政策宽松：</strong></p>
        <p>•先获批再投资</p>
        <p>•无语言要求</p>
        <p>•无管理经验要求</p>
        <p>•无移民监</p>
        <p>•6个月左右即可获批</p>
        <p><strong>护照含金量高：</strong></p>
        <p>爱尔兰护照的价值相当于英国护照+欧盟护照。</p>
        <p>•英国护照：根据爱尔兰与英国签署的共同区域协定CTA，爱尔兰公民前往英国无需申请签证，且在英国无需申请永居，进英国后可自动享有自由定居、工作、学习、享受社会福利甚至选举的权利</p>
        <p>•欧盟护照：爱尔兰是欧盟国家，持爱尔兰护照可以在欧盟成员国读书、工作</p>
        <p>跃迁爱尔兰投资移民项目-助您圆梦爱尔兰</p>
        <p><img class="size-full wp-image-23347 aligncenter" src="@/assets/news/12/16.jpg" alt="图片" width="573"></p>
        <p style="text-align: center;">（本文部分图片及信息来源：Bartra资产管理）</p>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "news12",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:12,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>